// TrackList.js
import React from "react";
import { Typography, Box, IconButton, TextField, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { colors } from "../lib/theme";
import TrackItem from "./TrackItem";

const TrackList = ({
  tracks,
  isPlaying,
  playingTrackId,
  handlePlayPause,
  currentTime,
  duration,
  handleSeek,
  user,
  userId,
  sharedWith,
  isRecording,
  recordingTrackId,
  selectedTrackId,
  stopRecording,
  startRecording,
  editingTrackId,
  editingTrackTitle,
  handleEditTrack,
  handleSaveTrackTitle,
  handleCancelEdit,
  setEditingTrackTitle,
  handleDeleteTrack,
  isAddingTrack,
  setIsAddingTrack,
  newTrackTitle,
  setNewTrackTitle,
  handleAddTrack,
  albumId,
  showTrackControls = true,
  remainingTime,
  playingSegmentIndex,
  handleDeleteSegment,
}) => {
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleAddTrack();
    }
  };

  return (
    <Box sx={{ width: '100%', maxWidth: { xs: '100%', sm: 'auto' } }}>
      <Typography variant="h5" sx={{ color: colors.gold, mb: 2 }}>
        Tracks
      </Typography>
      {tracks.length > 0 ? (
        <ol style={{ padding: 0, margin: 0 }}>
          {tracks.map((track, index) => (
            <TrackItem
              albumId={albumId}
              currentTime={currentTime}
              duration={duration}
              editingTrackId={editingTrackId}
              editingTrackTitle={editingTrackTitle}
              handleCancelEdit={handleCancelEdit}
              handleDeleteTrack={handleDeleteTrack}
              handleEditTrack={handleEditTrack}
              handlePlayPause={handlePlayPause}
              handleSaveTrackTitle={handleSaveTrackTitle}
              handleSeek={handleSeek}
              isPlaying={isPlaying}
              isRecording={isRecording}
              key={track.id || index}
              playingTrackId={playingTrackId}
              recordingTrackId={recordingTrackId}
              setEditingTrackTitle={setEditingTrackTitle}
              selectedTrackId={selectedTrackId}
              sharedWith={sharedWith}
              showTrackControls={showTrackControls}
              startRecording={startRecording}
              stopRecording={stopRecording}
              track={track}
              user={user}
              userId={userId}
              remainingTime={remainingTime}
              playingSegmentIndex={playingSegmentIndex}
              handleDeleteSegment={handleDeleteSegment}
            />
          ))}
        </ol>
      ) : (
        <Typography sx={{ color: colors.white }}>
          No tracks available
        </Typography>
      )}
      {user && (user.uid === userId || sharedWith.includes(user.uid)) && (
        <Box mt={2}>
          {isAddingTrack ? (
            <Box display="flex" alignItems="center">
              <TextField
                value={newTrackTitle}
                onChange={(e) => setNewTrackTitle(e.target.value)}
                onKeyPress={handleKeyPress}
                placeholder="Enter track title"
                size="small"
                sx={{ mr: 1, flexGrow: 1 }}
              />
              <IconButton onClick={handleAddTrack} sx={{ color: colors.white }}>
                <CheckIcon />
              </IconButton>
              <IconButton
                onClick={() => setIsAddingTrack(false)}
                sx={{ color: colors.white }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          ) : (
            <Button
              startIcon={<AddIcon />}
              onClick={() => setIsAddingTrack(true)}
              variant="secondary"
              sx={{ mt: 4 }}
            >
              Add New Track
            </Button>
          )}
        </Box>
      )}
    </Box>
  );
};

export default TrackList;
